import $ from 'jquery';
window.$ = window.jQuery = $;

import toastr from 'toastr';
window.toastr = toastr;

window.Bootstrap = require('bootstrap');

window.Dropzone = require('dropzone');

require('select2');

require('@eastdesire/jscolor');

import moment from 'moment';
window.moment = moment;

require('daterangepicker');
